<template>
  <section>
    <FilterLabel
      v-if="label"
      :label="label"
      :tooltip="tooltip"
    />
    <section class="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
      <input
        id="toggle"
        data-test="filter-switch"
        :checked="value"
        type="checkbox"
        name="toggle"
        class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
        @input="update(!!$event.target.checked)"
      />
      <label
        for="toggle"
        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
      ></label>
    </section>
  </section>
</template>

<script>
import FilterLabel from '@/components/contemporaryTheme/components/Base/FilterLabel'

export default {
  components: {
    FilterLabel,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
  },
}
</script>


<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
</style>
