<template>
  <div :class="{ hidden }">
    <FormLabel v-bind="{ label, id }" />
    <BaseSelect
      :value="value"
      :disabled="disabled"
      :multiple="config.multiple"
      :placeholder="placeholder"
      :options="options"
      :hide-empty-value="hideEmptyValue"
      :id="id"
      @input="update"
    >
      <template #option="{ option }">
        {{ getLabel(option) }}
      </template>
    </BaseSelect>
  </div>
</template>

<script>
import FormLabel from '@/components/Fields/FormLabel'

export default {
  components: { FormLabel },
  props: {
    value: {
      required: true,
      type: [String],
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => ({
        multiple: false,
      }),
    },
    customLabel: {
      type: Function,
      default: (option) => option,
    },
    hideEmptyValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    options() {
      return this.config.options || []
    },
  },
  methods: {
    getLabel(option) {
      return this.config.label ? option.label : this.customLabel(option)
    },
    update(val) {
      this.$emit('input', val)
    },
  },
}
</script>
