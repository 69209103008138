<template>
  <div>
    <component
      :is="filter.component"
      v-for="(filter,i) in filtersWithComponents"
      :key="i"
      class="mb-4 mx-1"
      v-bind="filter"
      :value="mappedObjectValues[filter.dataKey]"
      :tooltip="filter.tooltipEnabled ? filter.tooltip : ''"
      @input="update(filter,$event)"
    />
  </div>
</template>

<script>
import FilterSearchSelect from '~contemporaryTheme/components/Base/FilterSearchSelect'
import FilterListSelect from '~contemporaryTheme/components/Base/FilterListSelect'
import FilterSwitch from '~contemporaryTheme/components/Base/FilterSwitch'
import FilterBoolean from '~contemporaryTheme/components/Base/FilterBoolean'
import FilterInput from '~contemporaryTheme/components/Base/FilterInput'
import FilterCheckboxes from '~contemporaryTheme/components/Base/FilterCheckboxes'
import FilterSelectDynamicData from '~contemporaryTheme/components/Base/FilterSelectDynamicData'
import FilterRange from '~contemporaryTheme/components/Base/FilterRange'

export default {
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filtersWithComponents() {
      return this.filters.map((filter) => {
        switch (filter.type) {
          case 'boolean':
            return { ...filter, component: FilterBoolean }
          case 'yesNo':
            return { ...filter, component: FilterSwitch }
          case 'listSelect': // probably no longer used
            return { ...filter, component: FilterListSelect }
          case 'select':
          case 'searchSelect':
            return { ...filter, component: FilterSearchSelect }
          case 'multiselect':
            return { ...filter, component: FilterSearchSelect, multiple: true }
          case 'checkbox':
            return { ...filter, component: FilterCheckboxes }
          case 'range':
            return { ...filter, component: FilterRange }
          case 'radio':
            return {
              ...filter,
              component: FilterCheckboxes,
              singular: true,
            }
          case 'select_dynamic_data':
            return { ...filter, component: FilterSelectDynamicData }
          default:
            return { ...filter, component: FilterInput }
        }
      })
    },

    mappedObjectValues() {
      return Object.keys(this.values || {}).reduce((acc, key) => {
        let fieldKey
        if (key.includes('-greaterThanEquals')) {
          fieldKey = key.replace('-greaterThanEquals', '')
          acc[fieldKey] = {
            ...acc[fieldKey],
            greaterThanEquals: this.values[key],
          }
        } else if (key.includes('-lessThanEquals')) {
          fieldKey = key.replace('-lessThanEquals', '')
          acc[fieldKey] = {
            ...acc[fieldKey],
            lessThanEquals: this.values[key],
          }
        } else {
          acc[key] = this.values[key]
        }

        return acc
      }, {})
    },
  },
  methods: {
    update(filter, value) {
      if (filter.type === 'range') {
        this.$emit('input', {
          ...this.values,
          [filter.dataKey + '-greaterThanEquals']: value.greaterThanEquals
            ? value.greaterThanEquals
            : undefined,
          [filter.dataKey + '-lessThanEquals']: value.lessThanEquals
            ? value.lessThanEquals
            : undefined,
        })
      } else {
        this.$emit('input', { ...this.values, [filter.dataKey]: value })
      }
    },
  },
}
</script>
