import { computed, ref } from '@nuxtjs/composition-api'
import { CUSTOM_FILTERS } from '@/constants/featureFlags'
import useFeatureFlags from '@/composables/useFeatureFlags'

export default function useFilters(
  root,
  { type = 'courses', prebuiltFilters = ref([]) } = {}
) {
  const { customFiltersEnabled } = useFeatureFlags({
    customFiltersEnabled: CUSTOM_FILTERS,
  })
  const searchConfiguration = ref(undefined)
  const searchConfigurationId =
    type === 'courses'
      ? root.$store.state.settings.settings.courseSearchConfigurationId
      : root.$store.state.settings.settings.programSearchConfigurationId

  const customFilters = computed(
    () =>
      (searchConfiguration.value &&
        searchConfiguration.value.filters &&
        searchConfiguration.value.filters.map((filter) => ({
          ...filter,
          dataKey: filter.dataKey || filter.questionId,
        }))) ||
      []
  )
  const filtersData = computed(() => {
    const customFiltersAvailable =
      customFiltersEnabled && searchConfigurationId && searchConfiguration.value
    return customFiltersAvailable ? customFilters.value : prebuiltFilters.value
  })
  const filtersPayload = computed(() => {
    return filtersData.value.reduce((acc, curr) => {
      const id = curr.questionId
      acc[id] = curr.filter
      return acc
    }, {})
  })
  const filtersDefaultValues = computed(() => {
    return filtersData.value.reduce((acc, curr) => {
      const id = curr.questionId
      if (curr.config?.defaultValue) acc[id] = curr.config.defaultValue
      return acc
    }, {})
  })

  async function fetchSearchConfig() {
    const searchFiltersUrl = `/ca/${
      root.$store.state.settings.school
    }/search-configurations/${searchConfigurationId}`

    try {
      searchConfiguration.value = await root.$axios.$get(searchFiltersUrl)
      searchConfiguration.value &&
        searchConfiguration.value.filters &&
        searchConfiguration.value.filters.forEach((filter) => {
          if (!filter.dataKey) {
            filter.dataKey = filter.questionId
          }
        })
    } catch (e) {
      if (e.response.status !== 404) {
        root.$bugsnag.notify(e)
      }
    }
  }
  if (customFiltersEnabled && searchConfigurationId) {
    fetchSearchConfig()
  }

  return {
    filtersPayload,
    filtersData,
    customFiltersEnabled,
    filtersDefaultValues,
  }
}
