<template>
  <div>
    <FilterLabel
      v-if="label"
      :label="label"
      :tooltip="tooltip"
      :for-id="questionId"
    />
    <div>
      <v-select
        :options="sortedOptionsByLabel"
        :placeholder="placeholder || config && config.placeholder"
        :value="selectedOption"
        :multiple="multiple"
        :input-id="questionId"
        data-test="filter-search-select"
        @input="update($event)"
      />
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import FilterLabel from '@/components/contemporaryTheme/components/Base/FilterLabel'

export default {
  components: {
    vSelect,
    FilterLabel,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Array],
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    questionId: {
      type: String,
      default: '',
    }
  },
  computed: {
    optionsWithlabels() {
      return (this.config.options || []).map(
        (opt) => (opt.label ? opt : { label: opt, value: opt })
      )
    },
    sortedOptionsByLabel() {
      return [...(this.optionsWithlabels || [])].sort(
        (a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)
      )
    },
    selectedOption() {
      const selValue = this.multiple
        ? this.sortedOptionsByLabel.filter(({ value }) =>
            (this.value || []).includes(value)
          )
        : this.sortedOptionsByLabel.find(({ value }) => value === this.value)
      return selValue
    },
  },
  mounted() {
    if (document) {
      const dropdownToggles = document.querySelectorAll('.vs__dropdown-toggle')
      dropdownToggles.forEach((el) => {
        if (!el.getAttribute('aria-controls')) {
          el.setAttribute('aria-controls', el.getAttribute('aria-owns') || el.id.split('_')[0] + '__listbox')
        }

        // get id of closest label
        const label = el.closest('label')
        el.setAttribute('aria-label', label ? label.textContent : el.getAttribute('aria-label') + el.getAttribute('aria-controls'))
      })

      const searchInputs = document.querySelectorAll('.vs__search')
      searchInputs.forEach((el) => {
        el.setAttribute('aria-labelledby', el.getAttribute('aria-controls') || el.id.split('_')[0] + '__listbox')
      })
    }
  },
  methods: {
    update($event) {
      // first `item` check needed to support `null` value when select is cleared
      const itemValue = (item) => (item && item.value) || item
      this.$emit(
        'input',
        Array.isArray($event) ? $event.map(itemValue) : itemValue($event)
      )
    },
  },
}
</script>

<style>
.v-select {
  position: relative;
  font-family: inherit;
}
.v-select,
.v-select * {
  box-sizing: border-box;
}
@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  cursor: not-allowed;
  background-color: #f8f8f8;
}
.v-select[dir='rtl'] .vs__actions {
  padding: 0 3px 0 6px;
}
.v-select[dir='rtl'] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}
.v-select[dir='rtl'] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}
.v-select[dir='rtl'] .vs__dropdown-menu {
  text-align: right;
}
.vs__dropdown-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: 0 0 4px;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
  min-height: 48px;
}
.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
  align-items: center;
}
.vs__actions {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px;
}
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}
.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}
.vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vs__open-indicator {
  fill: var(--textFontColor, #848994);
  transform: scale(1);
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}
.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}
.vs--loading .vs__open-indicator {
  opacity: 0;
}
.vs__clear {
  fill: var(--textFontColor, #848994);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}
.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
  max-height: 150px;
}
.vs__no-options {
  text-align: center;
}
.vs__dropdown-option {
  line-height: 1.42857143;
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}
.vs__dropdown-option--highlight {
  background: #d5d9f6;
  color: #fff;
}
.vs__dropdown-option--deselect {
  background: #fb5858;
  color: #fff;
}
.vs__dropdown-option--disabled {
  background: inherit;
  color: rgba(60, 60, 60, 0.5);
  cursor: inherit;
}
.vs__selected {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 4px 2px 0;
  padding: 0 0.25em;
  z-index: 0;
}
.vs__deselect {
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: var(--textFontColor, #848994);
  text-shadow: 0 1px 0 #fff;
}
.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}
.vs--single.vs--loading .vs__selected,
.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}
.vs__search::-webkit-search-cancel-button {
  display: none;
}
.vs__search::-ms-clear,
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration {
  display: none;
}
.vs__search,
.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  background: none;
  box-shadow: none;
  max-width: 100%;
  z-index: 1;
  flex-grow: 1;
  margin: 2px 0 0;
  line-height: 1.4;
  @apply p-2;
}
.vs__search:not(:focus) {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
}
.vs--unsearchable .vs__search {
  opacity: 1;
}
.vs--unsearchable:not(.vs--disabled) .vs__search {
  cursor: pointer;
}
.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border: 0.9em solid hsla(0, 0%, 39.2%, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
  transition: opacity 0.1s;
}
.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.vs--loading .vs__spinner {
  opacity: 1;
}
.vs__dropdown-toggle {
  @apply bg-white rounded w-full;
}
.v-select,
.v-select * {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, inherit);
  font-weight: var(--interfaceFontWeight, inherit);
}
</style>
