<template>
  <section>
    <FilterLabel
      v-if="label"
      :label="label"
      :tooltip="tooltip"
    />
    <ul
      v-if="config.showAllOptions"
      :class="{ 'flex items-center border rounded': groupped }"
    >
      <li
        v-for="(option,i) in options"
        :key="i"
        :data-test="option + '-option'"
        :class="{ 'filter-item--selected': isOptionSelected(option), 'flex-1 px-2 text-center': groupped, 'border-r-0': isLastEl(i) && groupped, 'border-r': !isLastEl(i) && groupped }"
        class="my-1 py-1 filter-item transition-all duration-300 ease-in-out text-sm"
      >
        <label
          v-if="isMultiSelect"
          class="flex cursor-pointer"
        >
          <input
            :checked="isOptionSelected(option)"
            type="checkbox"
            class="mr-1"
            @input="selectMultiple($event.target.checked, option)"
          />
          <span
            class="flex-1"
            :class="{ 'underline': isOptionSelected(option) }"
          >{{ customLabel(option) }}</span>
        </label>
        <button
          v-else
          class="text-left"
          @click="selectSingle(option)"
        >
          <span
            class="font-bold"
            :class="{ 'underline': isOptionSelected(option) }"
          >{{ customLabel(option) }}</span>
        </button>
      </li>
      <div
        v-if="showMinimum && allOptions.length>showMinimum"
        class="mt-2 more-item underline text-xs cursor-pointer"
      >
        <button
          v-if="isExpanded"
          class="font-bold"
          data-test="showLess"
          @click="isExpanded = false"
        >Show Less Options</button>
        <button
          v-else
          class="font-bold"
          data-test="showMore"
          @click="isExpanded = true"
        >Show More Options</button>
      </div>
    </ul>
  </section>
</template>

<script>
import FilterLabel from '@/components/contemporaryTheme/components/Base/FilterLabel'

export default {
  components: {
    FilterLabel,
  },
  props: {
    groupped: {
      type: Boolean,
      default: false,
    },
    showMinimum: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Array],
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    customLabel: {
      type: Function,
      default: (v) => v,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    allOptions() {
      return this.config.options || []
    },
    options() {
      if (!this.showMinimum) return this.allOptions
      return this.isExpanded
        ? this.allOptions
        : this.allOptions.slice(0, this.showMinimum)
    },
    valueWithDefault() {
      if (this.config.multiple && !this.value) return []
      return this.value
    },
    isMultiSelect() {
      return this.config && this.config.multiple
    },
  },
  methods: {
    isLastEl(index) {
      return this.options.length - 1 === index
    },
    isOptionSelected(option) {
      return Array.isArray(this.valueWithDefault)
        ? this.valueWithDefault.includes(option)
        : this.valueWithDefault === option
    },
    update(value) {
      this.$emit('input', value)
    },
    selectSingle(value) {
      if (this.config.multiple) return
      const actualValue = value.value ? value.value : value
      this.update(actualValue === this.value ? null : actualValue)
    },
    selectMultiple(checked, value) {
      const actualValue = value.value ? value.value : value
      if (checked) this.update([...this.valueWithDefault, actualValue])
      else this.update(this.valueWithDefault.filter((v) => v !== actualValue))
    },
  },
}
</script>

<style scoped>
.more-item,
.filter-item {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, var(--text-gray-500));
}
.filter-item {
  font-weight: var(--interfaceFontWeight, 700);
}
.filter-item:hover,
.filter-item:focus,
.filter-item--selected {
  color: var(--text-blue-600);
}
.more-item {
  font-weight: var(--interfaceFontWeight, inherit);
}
</style>
