<template>
  <div>
    <FilterLabel
      v-if="label"
      :label="label"
      :for="id"
    />
    <div class="relative">
      <div class="absolute left-0 top-0 mt-3 ml-3">
        <slot name="iconStart"></slot>
      </div>
      <input
        :id="id"
        class="rounded p-3 w-full border"
        v-bind="config"
        data-test="filter-input"
        :class="{ 'pl-8': $slots.iconStart }"
        :placeholder="placeholder"
        :value="value"
        @input="update($event.target.value)"
      >
    </div>
  </div>
</template>

<script>
import _uniqueId from 'lodash/uniqueId'
import FilterLabel from '@/components/contemporaryTheme/components/Base/FilterLabel'

export default {
  components: {
    FilterLabel,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.id = _uniqueId('field_')
  },
  methods: {
    update(value) {
      this.$emit(
        'input',
        this.config.type === 'number' ? parseInt(value) : value
      )
    },
  },
}
</script>

<style scoped>
input {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, inherit);
  font-weight: var(--interfaceFontWeight, inherit);
}
</style>
