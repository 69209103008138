import { computed, ref, watch } from '@nuxtjs/composition-api'
import { debounce } from '@/utils/generalUtils'
import CoursesService from '@/services/courses'
import { SORT_ENTITIES_ALPHABETICLY_BY_DISPLAYED_VALUE } from '@/constants/featureFlags'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { LIMIT_MEDIUM } from '@/constants/pagination'
import { convertQueryToFilters } from '@/utils/filters'
import usePrint from '@/composables/usePrint'

const sortByOptions = [
  'code',
  'name',
  'subjectCode',
  'department',
  'description',
  'career',
]

export default function useCourses(
  root,
  {
    pageSize = LIMIT_MEDIUM,
    columns,
    filtersPayload,
    formatDependents = false,
  } = {}
) {
  const { DISPLAY_NAME_ENTITY_SORTING_ENABLED } = useFeatureFlags({
    DISPLAY_NAME_ENTITY_SORTING_ENABLED: SORT_ENTITIES_ALPHABETICLY_BY_DISPLAYED_VALUE,
  })
  const api = new CoursesService(root.$axios, root.$store)

  const school = root.$store.state.settings.school
  const departments = root.$store.state.departments.all
  const coursesFilters = root.$store.state.settings.coursesFilters

  const { isInPrintMode, printIfInPrintMode } = usePrint(root)

  const courseQuestions = ref({})
  const courses = ref([])

  const { pq, page, department, ...queryFilters } = root.$route.query || {}
  const filters = ref({ ...queryFilters, department }) // explicitly defining departments for watcher to work

  const total = ref(0)
  const currentPage = ref(1)
  const isLoading = ref(false)
  const searchQuery = ref(root.$route.query.cq || '')
  const sortBy = ref(root.$route.query.sortBy || '')

  watch(searchQuery, () => {
    isLoading.value = true
    searchDebounced()
  })

  const departmentFilter = computed({
    get() {
      const department = departments.find(
        (dep) => dep.id === filters.value.department
      )
      return department ? department.id : ''
    },
    set(dep) {
      filters.value.department = dep || ''
    },
  })
  const totalPages = computed(() => {
    return Math.ceil(total.value / pageSize)
  })
  const hasFilters = computed(() => {
    return coursesFilters?.filters && coursesFilters?.filters.length > 0
  })

  const hasDepartmentFilter = computed(() => {
    return (
      hasFilters.value &&
      coursesFilters.filters.some((filter) => {
        return (
          filter.name === 'departments' || filter.id === 'departments-course'
        )
      })
    )
  })
  const coursesWithDepartment = computed(() => {
    return courses.value.map((course) => {
      const department = departments.find(
        (dep) => dep.id === course.departments[0]
      )
      return {
        ...course,
        department,
      }
    })
  })
  const searchDebounced = debounce(search, 500)

  async function fetchQuestion() {
    courseQuestions.value = await root.$axios.$get(
      `/${school}/general/courseTemplate/questions`
    )
  }

  const DEFAULT_SORT_FIELDS_ORDER = DISPLAY_NAME_ENTITY_SORTING_ENABLED
    ? ['catalogDisplayName', 'transcriptDescription', 'longName', 'name']
    : 'code'

  async function fetchData({
    sortBy: defaultSortBy = DEFAULT_SORT_FIELDS_ORDER,
  } = {}) {
    isLoading.value = true
    const { cq, page = 1, department, sortBy, ...queryFilters } =
      root.$route.query || {}

    const limit = isInPrintMode.value ? 0 : pageSize
    const skip = Math.max(0, page - 1) * pageSize

    try {
      const { filters = [] } = coursesFilters || {}
      const departmentFilter = {
        id: 'departments-course',
        name: 'departments',
        inputType: 'select',
        group: 'course',
        type: 'contains',
        value: [department],
      }

      const filtersFromQuery = convertQueryToFilters(
        queryFilters,
        courseQuestions,
        filtersPayload
      )

      // Merge default filters with user defined filters set in route query and department filter
      const filtersMerged = {
        condition: 'AND',
        filters: [
          coursesFilters || null,
          filtersFromQuery.length > 0 && {
            condition: 'AND',
            filters: filtersFromQuery
          },
          department && departmentFilter
        ].filter(Boolean),
      }
      
      const { data, listLength } = await api.getCourses({
        ...(cq && { query: cq }),
        sortBy: sortBy || defaultSortBy,
        skip,
        limit,
        columns,
        formatDependents,
        filters: filtersMerged,
      })
      courses.value = data
      currentPage.value = page
      total.value = listLength
    } catch (e) {
      this.$bugsnag.notify(e)
      console.warn('Error while fetching search results.')
    } finally {
      isLoading.value = false
      printIfInPrintMode()
    }
  }

  async function queryData({ query = '$filters', limit = 20, skip = 0 }) {
    isLoading.value = true

    try {
      const { data, listLength } = await api.getCourses({
        query,
        sortBy: DEFAULT_SORT_FIELDS_ORDER,
        skip,
        limit,
        columns,
        filters: {
          ...(coursesFilters || {}),
        },
      })
      courses.value = data
      total.value = listLength
      return data
    } catch (e) {
      this.$bugsnag.notify(e)
      console.warn('Error while fetching search results.')
    } finally {
      isLoading.value = false
    }
  }

  function goToCourse(course) {
    root.$router.push(`/courses/${encodeURIComponent(course.courseGroupId)}`)
  }
  function getCourseUrl(course) {
    return course ? `/courses/${encodeURIComponent(course.courseGroupId)}` : '#'
  }
  function search() {
    root.$router.push({
      name: 'courses',
      query: {
        ...filters.value,
        page: 1,
        cq: searchQuery.value,
      },
    })
  }
  function updatePage(page) {
    root.$router.push({
      name: 'courses',
      query: {
        ...root.$route.query,
        page,
      },
    })
  }

  function updateQuery(query) {
    root.$router.push({
      name: 'courses',
      query: {
        ...root.$route.query,
        ...query,
      },
    })
  }

  function getDirector(contacts) {
    const director = contacts.find((contact) => contact.type === 'director')
    return director ? director.name : ''
  }

  return {
    getDirector,
    updatePage,
    search,
    goToCourse,
    getCourseUrl,
    fetchData,
    queryData,
    searchDebounced,
    coursesWithDepartment,
    hasDepartmentFilter,
    hasFilters,
    totalPages,
    departmentFilter,
    departments,
    coursesFilters,
    courses,
    filters,
    total,
    currentPage,
    isLoading,
    searchQuery,
    updateQuery,
    pageSize,
    fetchQuestion,
    courseQuestions,
    sortByOptions,
    sortBy,
  }
}
