<template>
  <label
    class="text-xs mb-1 block"
    data-test="filter-label"
    :for="forId"
    :id="`label-${forId}`"
  >
  {{ label }}
  <BaseIcon
    v-if="tooltip"
    v-tooltip.top="{
      content: tooltip,
      boundariesElement: 'body',
    }"
    :icon="'info-circle'"
    class="ml-2"
  />
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    forId: {
      type: String,
      default: null,
    }
  },
}
</script>
<style scoped>
label {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, var(--text-gray-400));
  font-weight: var(--interfaceFontWeight, 700);
}
</style>
