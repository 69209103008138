<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="text-grey-darkest mb-2 text-sm block font-semibold"
    >
      <span>{{ label }}</span>
      <span
        v-if="required"
        :id="`${id}-help`"
        class="text-red-dark"
        aria-hidden="true"
      >
        *
      </span>
    </label>
    <div
      v-if="description"
      class="pb-2"
    >
      <span class="text-sm font-light">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '-',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
