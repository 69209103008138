<template>
  <aside
    v-on-clickaway="handleClickAway"
    class="sidebar-wrapper md:z-50 z-40"
    :aria-label="name"
    :class="{
      'opened': open,
      'left-0 position-left': position === 'left',
      'right-0 position-right': position === 'right'
      }"
  >
    <slot name="sidebar-heading">
      <h1
        v-if="heading"
        class="heading-1"
      >{{ heading }}</h1>
    </slot>
    <div class="w-full h-full">
      <slot />
    </div>
  </aside>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [clickaway],
  props: {
    heading: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left', // valid props: left, right
    },
    open: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    }
  },
  watch: {
    open() {
      if (this.open) this.disableScroll()
      else this.allowScroll()
    },
  },
  beforeDestroy() {
    this.allowScroll()
  },
  methods: {
    handleClickAway(evt) {
      this.$emit('close', evt)
    },
    allowScroll() {
      document.querySelector('body').classList.remove('disable-scroll')
      document.querySelector('html').classList.remove('disable-scroll')
    },
    disableScroll() {
      document.querySelector('body').classList.add('disable-scroll')
      document.querySelector('html').classList.add('disable-scroll')
    },
  },
}
</script>

<style scoped>
.heading-1 {
  @apply text-2xl mb-2;
  color: var(--h1HeaderFontColor, var(--text-gray-900));
  font-family: var(--h1HeaderFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 700);
}

.sidebar-wrapper {
  @apply top-0 fixed w-full h-full rounded-none p-4 bg-white transition-all ease-in-out duration-300;
  max-width: 20rem;
}

.position-left {
  transform: translateX(-100%);
}

.position-right {
  transform: translateX(100%);
}

.sidebar-wrapper.opened {
  transform: translateX(0);
}

.sidebar-wrapper.opened.position-left {
  box-shadow: 10px 0px 50px 10px #34343436;
}

.sidebar-wrapper.opened.position-right {
  box-shadow: -10px 0px 50px 10px #34343436;
}
</style>
