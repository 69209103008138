<template>
  <div class="flex flex-col items-center p-15 w-full">
    <BaseIcon
      icon="folder-open"
      class="block opacity-50"
      style="width:160px;height:160px;"
    />
    <h1
      v-if="label"
      class="text-2xl mb-2"
    >{{label}}</h1>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
h1 {
  color: var(--h1HeaderFontColor, var(--text-gray-400));
  font-family: var(--h1HeaderFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 700);
}
</style>
