<template>
  <section>
    <BaseAccordion
      :initially-open="true"
      :ui-id="filterId"
    >
    <template #trigger="{ setIsOpen, isOpen }">
      <button
        :id="`${filterId}-header`"
        :aria-expanded="isOpen ? 'true' : 'false'"
        :aria-controls="`${filterId}-panel`"
        class="accordion-button flex items-center justify-between w-full focus:outline-none color-deep-black md:pt-2 font-semibold"
        @click="setIsOpen(!isOpen)"
        @keyup.space="setIsOpen(!isOpen)"
      >
        <FilterLabel
          :label="label"
          :tooltip="tooltip"
          :for-id="`${filterId}-panel`"
        />
        <BaseIcon
          icon="chevron-down"
          class="toggle-icon"
          aria-hidden="true"
          :class="{ 'rotated': isOpen }"
        />
      </button>
    </template>
    <template #content>
      <ul
        :class="{ 'flex items-center border rounded': groupped }"
      >
        <li
          v-for="(option,i) in options"
          :key="i"
          :class="{ 'filter-item--selected': isOptionSelected(option) }"
          data-test="choice-label"
          class="my-1 py-1 filter-item transition-all duration-300 ease-in-out text-sm"
        >
          <label
            class="flex items-center cursor-pointer"
          >
            <input
              :checked="isOptionSelected(option)"
              :type="singular ? 'radio' : 'checkbox'"
              class="mr-1"
              @input="select($event.target.checked, option)"
            />
            <span
              class="flex-1"
              :class="{ 'underline': isOptionSelected(option) }"
            >{{ customLabel(option) }}</span>
          </label>
        </li>
      </ul>
    </template>
    </BaseAccordion>
  </section>
</template>

<script>
import FilterLabel from '~contemporaryTheme/components/Base/FilterLabel'

export default {
  components: {
    FilterLabel,
  },
  props: {
    groupped: {
      type: Boolean,
      default: false,
    },
    showMinimum: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Array],
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    customLabel: {
      type: Function,
      default: (v) => v.label,
    },
    foldable: {
      type: Boolean,
      default: true,
    },
    singular: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
      filterId: Math.random().toString(36),
    }
  },
  computed: {
    allOptions() {
      return this.config.options || []
    },
    options() {
      if (!this.showMinimum) return this.allOptions
      return this.isExpanded
        ? this.allOptions
        : this.allOptions.slice(0, this.showMinimum)
    },
    valueWithDefault() {
      if (!this.value) return []
      return this.value
    },
  },
  methods: {
    isLastEl(index) {
      return this.options.length - 1 === index
    },
    isOptionSelected(option) {
      const actualOptionValue = option.value ? option.value : option
      return Array.isArray(this.valueWithDefault)
        ? this.valueWithDefault.includes(actualOptionValue)
        : this.valueWithDefault === actualOptionValue
    },
    update(value) {
      this.$emit('input', value)
    },
    select(checked, value) {
      if (this.singular) {
        this.selectSingle(value)
      } else {
        this.selectMultiple(checked, value)
      }
    },
    selectSingle(value) {
      const actualValue = value.value ? value.value : value
      this.update(actualValue === this.value ? null : actualValue)
    },
    selectMultiple(checked, value) {
      const actualValue = value.value ? value.value : value
      if (checked) this.update([...this.valueWithDefault, actualValue])
      else this.update(this.valueWithDefault.filter((v) => v !== actualValue))
    },
  },
}
</script>

<style scoped>
.filter-item {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, var(--text-gray-500));
}
.filter-item {
  font-weight: var(--interfaceFontWeight, 700);
}
.filter-item:hover,
.filter-item:focus,
.filter-item--selected {
  color: var(--text-blue-600);
}
</style>
