<template>
  <section>
    <FilterLabel
      v-if="label"
      :label="label"
    />

    <div>
      <v-select
        :options="optionsWithlabels"
        :placeholder="placeholder || config && config.placeholder"
        :value="selectedOption"
        :multiple="multiple"
        data-test="filter-search-select"
        :filter="filterData"
        :selectable="option => option.value"
        @input="update($event)"
      />
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import FilterLabel from '@/components/contemporaryTheme/components/Base/FilterLabel'
import useCourses from '@/composables/useCourses'
import usePrograms from '@/composables/usePrograms'
import { debounce } from '@/utils/generalUtils'
import { courseNameWithFallbacks } from '@/utils/entityNames'

export default {
  components: {
    vSelect,
    FilterLabel,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    questionType: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Array],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      offset: 0,
      limit: 20,
      defaultOptions: [
        {
          label: 'Type to search',
          value: '',
        },
      ],
    }
  },
  setup(props, { root }) {
    const searchQuery = ref('')

    let useDataSource, filteredData

    switch (props.questionType) {
      case 'courseSelect':
        useDataSource = useCourses(root)
        filteredData = useDataSource.courses
        break
      case 'programSelect':
        useDataSource = usePrograms(root)
        filteredData = useDataSource.programs
        break
      default:
        break
    }

    const retrieveData = () => {
      const queryData = useDataSource.queryData
      queryData({ query: searchQuery.value, limit: 50 })
    }

    const retrieveDataDebounced = debounce(retrieveData, 500)

    return {
      filteredData,
      searchQuery,
      retrieveDataDebounced,
    }
  },
  computed: {
    multiple() {
      return this.config?.multiple || false
    },
    options() {
      if (!this.filteredData?.length) return this.defaultOptions
      return this.filteredData.map((data) => ({
        ...data,
        label: courseNameWithFallbacks(data),
        value: this.getFilterableId(data),
      }))
    },
    optionsWithlabels() {
      return (this.options || []).map(
        (opt) => (opt.label ? opt : { label: opt, value: opt })
      )
    },
    selectedOption() {
      return this.optionsWithlabels.find(({ value }) => value === this.value)
    },
  },
  methods: {
    update($event) {
      const itemValue = (item) => (item && item.value) || item
      this.$emit(
        'input',
        Array.isArray($event) ? $event.map(itemValue) : itemValue($event)
      )
    },
    filterData(options, query) {
      if (this.searchQuery === query) return options
      this.searchQuery = query
      this.retrieveDataDebounced()
      return options
    },
    getFilterableId(option) {
      switch (this.questionType) {
        case 'courseSelect':
          return option.courseGroupId
        case 'programSelect':
          return option.programGroupId
        default:
          return option._id
      }
    },
  },
}
</script>

<style scoped>
.more-item,
.filter-item {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, var(--text-gray-500));
}
.filter-item {
  font-weight: var(--interfaceFontWeight, 700);
}
.filter-item:hover,
.filter-item:focus,
.filter-item--selected {
  color: var(--text-blue-600);
}
.more-item {
  font-weight: var(--interfaceFontWeight, inherit);
}
</style>
