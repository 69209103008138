<template>
  <section>
    <BaseAccordion
      :initially-open="true"
    >
    <template #trigger="{ setIsOpen, isOpen }">
      <button
        :id="`${filterId}-header`"
        :aria-expanded="isOpen ? 'true' : 'false'"
        class="accordion-button flex items-center justify-between w-full focus:outline-none color-deep-black md:pt-2 font-semibold"
        @click="setIsOpen(!isOpen)"
        @keyup.space="setIsOpen(!isOpen)"
      >
          <FilterLabel
            :label="label"
            :tooltip="tooltip"
          />
        <BaseIcon
          icon="chevron-down"
          class="toggle-icon"
          aria-hidden="true"
          :class="{ 'rotated': isOpen }"
        />
      </button>
    </template>
    <template #content>
      <div class="flex-grow flex">
        <FilterInput 
          label="Min" 
          placeholder="" 
          data-test="filter-range-min"
          :value="value.greaterThanEquals" 
          :config="{ type: questionType}"
          class="m-3 ml-0" 
          style="width:calc(50% - 0.75em)"
          @input="update({greaterThanEquals:$event})"
        />
        <FilterInput 
          label="Max" 
          placeholder="" 
          data-test="filter-range-max"
          :value="value.lessThanEquals" 
          :config="{ type: questionType, min: value.greaterThanEquals}"
          class="m-3 mr-0"
          style="width:calc(50% - 0.75em)"
          @input="update({lessThanEquals:$event})"
        />
      </div>
    </template>
    </BaseAccordion>
  </section>
</template>

<script>
import FilterLabel from '~contemporaryTheme/components/Base/FilterLabel'
import FilterInput from '~contemporaryTheme/components/Base/FilterInput'

export default {
  components: {
    FilterLabel,
    FilterInput,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    questionType: {
      type: String,
      default: 'number',
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({ greaterThanEquals: null, lessThanEquals: null }),
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
      filterId: Math.random().toString(36),
    }
  },
  methods: {
    update(value) {
      this.$emit('input', { ...this.value, ...value })
    },
  },
}
</script>

<style scoped>
.filter-item {
  font-family: var(--interfaceFontTypeface, inherit);
  color: var(--interfaceFontColor, var(--text-gray-500));
}
.filter-item {
  font-weight: var(--interfaceFontWeight, 700);
}
.filter-item:hover,
.filter-item:focus,
.filter-item--selected {
  color: var(--text-blue-600);
}
</style>
