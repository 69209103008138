<template>
  <section>
    <FilterLabel
      v-if="label"
      :label="label"
      :tooltip="tooltip"
      :id="questionId"
    />
    <section class="relative inline-block align-middle select-none transition duration-200 ease-in">
      <BaseButton
        class="inline-block rounded color-theme-navbar-link hover:opacity-80"
        :type="isYesSelected ? 'primary' : 'secondary'"
        :aria-describedby="questionId"
        @click="update(true)"
      >
        Yes
      </BaseButton>
      <BaseButton
        class="inline-block rounded color-theme-navbar-link hover:opacity-80"
        :type="isNoSelected ? 'primary' : 'secondary'"
        :aria-describedby="questionId"
        @click="update(false)"
      >
        No
      </BaseButton>
    </section>
  </section>
</template>

<script>
import FilterLabel from '~contemporaryTheme/components/Base/FilterLabel'

export default {
  components: {
    FilterLabel,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    questionId: {
      type: String,
      default: '',
    },
  },
  computed: {
    isYesSelected() {
      return this.value === true || this.value === 'true'
    },
    isNoSelected() {
      return this.value === false || this.value === 'false'
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
  },
}
</script>


<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
</style>
